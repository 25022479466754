define("discourse/plugins/discourse-data-explorer/discourse/components/data-explorer-bar-chart", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "discourse/lib/load-script", "discourse-common/utils/decorators", "discourse/plugins/discourse-data-explorer/discourse/lib/themeColor", "@ember/template-factory"], function (_exports, _component, _component2, _object, _loadScript, _decorators, _themeColor, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <canvas
    {{did-insert this.initChart}}
    {{on "change" this.updateChartData}}
  ></canvas>
  */
  {
    "id": "Qxy000nV",
    "block": "[[[11,\"canvas\"],[4,[38,1],[[30,0,[\"initChart\"]]],null],[4,[38,2],[\"change\",[30,0,[\"updateChartData\"]]],null],[12],[13]],[],false,[\"canvas\",\"did-insert\",\"on\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/data-explorer-bar-chart.hbs",
    "isStrictMode": false
  });
  class DataExplorerBarChart extends _component2.default {
    chart;
    barsColor = (() => (0, _themeColor.default)("--tertiary"))();
    barsHoverColor = (() => (0, _themeColor.default)("--tertiary-high"))();
    gridColor = (() => (0, _themeColor.default)("--primary-low"))();
    labelsColor = (() => (0, _themeColor.default)("--primary-medium"))();
    get config() {
      const data = this.data;
      const options = this.options;
      return {
        type: "bar",
        data,
        options
      };
    }
    get data() {
      const labels = this.args.labels;
      return {
        labels,
        datasets: [{
          label: this.args.datasetName,
          data: this.args.values,
          backgroundColor: this.barsColor,
          hoverBackgroundColor: this.barsHoverColor
        }]
      };
    }
    get options() {
      return {
        scales: {
          legend: {
            labels: {
              fontColor: this.labelsColor
            }
          },
          xAxes: [{
            gridLines: {
              color: this.gridColor,
              zeroLineColor: this.gridColor
            },
            ticks: {
              fontColor: this.labelsColor
            }
          }],
          yAxes: [{
            gridLines: {
              color: this.gridColor,
              zeroLineColor: this.gridColor
            },
            ticks: {
              beginAtZero: true,
              fontColor: this.labelsColor
            }
          }]
        }
      };
    }
    async initChart(canvas) {
      await (0, _loadScript.default)("/javascripts/Chart.min.js");
      const context = canvas.getContext("2d");
      // eslint-disable-next-line
      this.chart = new Chart(context, this.config);
    }
    static #_ = (() => dt7948.n(this.prototype, "initChart", [_decorators.bind]))();
    updateChartData() {
      this.chart.data = this.data;
      this.chart.update();
    }
    static #_2 = (() => dt7948.n(this.prototype, "updateChartData", [_object.action]))();
    willDestroy() {
      super.willDestroy(...arguments);
      this.chart.destroy();
    }
  }
  _exports.default = DataExplorerBarChart;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataExplorerBarChart);
});